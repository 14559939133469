import { Card } from "./Portfolio-Card";
import data from "../data";

export const Portfolio = () => {
  const cards = data.map((item) => {
    return <Card key={item.id} {...item} />;
  });

  return (
    <div className="portfolio">
      <div className="portfolio__header">
        <h1>Projects</h1>
      </div>
      <div className="portfolio__content">{cards}</div>
    </div>
  );
};
