import img1 from "./images/img1.webp";
import img2 from "./images/img2.webp";
import img3 from "./images/img3.webp";
import pixi from "./images/pixi.png";
import react from "./images/react.png";
import threejs from "./images/threejs.png";
import webgl from "./images/webgl.png";

export default [
  {
    id: 1,
    title: "Image Gallery",
    description: "responsive image gallery built with Pixi.js and React",
    alt: "Picture of website for an image gallery",
    link: "https://image-gallery-artemy.netlify.app",
    img: img2,
    tools: [pixi, react],
  },
  {
    id: 2,
    title: "Bananas",
    description: "creative website utilizing 3d assets with mock login page",
    alt: "Picture of website for a creative website utilizing 3d assets made with React Three Fibre ",
    link: "https://bananas-artemy.netlify.app",
    img: img1,
    tools: [react, threejs],
  },
  {
    id: 3,
    title: "Earth",
    description:
      "under construction! 3d interactive globe with list of cities and weather data!",
    alt: "Picture of a website with 3d earth made with ",
    img: img3,
    tools: [threejs, webgl],
  },
];
