export const Card = (props) => {
  const tools = props.tools.map((tool) => {
    return <img src={tool} alt="" />;
  });

  return (
    <div className="card">
      <div className="card__content">
        <div className="card__content-img">
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            <img src={props.img} alt={props.alt} />
          </a>
        </div>
        <div className="card__content-info">
          <div className="info-1">
            <h3>{props.title}</h3>
            <p>{props.description}</p>
          </div>
          <div className="info-2">
            <h3>Tools</h3>
            {tools}
          </div>
        </div>
      </div>
    </div>
  );
};
