import { createRoot } from "react-dom/client";
import { Portfolio } from "./Components/Portfolio";

const App = () => {
  return (
    <main>
      <Portfolio />
    </main>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
